<template>
    <div style="padding-top: 8em;">
        <section class="intro clearfix header_service">
            <div class="container" style="max-width:65em; min-height:20em">
                <div class="row">
                <div class="overlay-green"></div>
                    <v-col class="col-md-8 intro-info text-left align-self-center" md="8" style="z-index: 1;">
                        <h1 class="text-uppercase bold text-white mb-4 ls-1 w-75">{{umkm ? umkm.title : ''}}</h1>
                        <p class="mb-5 text-white h5">{{umkm ? umkm.description : ''}}
                        </p>
                    </v-col>
                    <v-col class="col-md-4" md="4" style="z-index: 1;">
                        <div class="card text-darkgreen">
                            <div class="card-header pt-5 pb-5 border-0">
                                <p class="mt-2 text-center mb-0 pb-0"><s>{{umkm ? umkm.discount_price : ''}}</s></p>
                                <h3 class="text-center mb-0">
                                    IDR {{umkm ? umkm.price : ''}}
                                </h3>
                            </div>
                            <div class="card-body">
                                <p>Syarat :</p>
                                <p class="mb-0 pb-0 mt-2" v-for="(detail, index) in details" :key="index">
                                    <v-icon color="primary">{{detail.icon.trim().substr(0,3) == 'mdi' ? detail.icon : 'mdi-' + detail.icon}}</v-icon> {{detail.name}}
                                </p>
                                <p>Tahapan :</p>
                                <p class="mt-2 mb-0 pb-0" v-for="(detail, index) in facilities" :key="index">
                                    <v-icon color="primary">{{detail.icon.trim().substr(0,3) == 'mdi' ? detail.icon : 'mdi-' + detail.icon}}</v-icon> {{detail.name}}
                                </p>
                                <a :href="umkm ? umkm.url_link_wa : 'https://api.whatsapp.com/send?phone=+6282120938886&text=Hi%20LegalPedia,%20Saya%20ingin%20konsultasi%20.%20Mohon%20Dibantu'" target="_blank">
                                    <v-btn block small color="primary" class="p-4 mt-5">Dapatkan Sekarang</v-btn>
                                </a>
                                
                            </div>
                        </div>
                    </v-col>
                </div>
            </div>
        </section>
        <section class="mt-12">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h3 class="font-open-sans text-dark mb-4 bold">
                            Pertanyaan Umum Seputar Izin UMKM
                        </h3>
                    </v-col>
                    <v-col cols="12">
                        <v-expansion-panels
                        v-model="panel"
                        multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>Bagaimana saya bisa mengetahui perizinan berusaha yang diperlukan untuk bisnis saya adalah UMK-Perorangan?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                
                                Untuk mengetahuinya, kamu dapat membandingkan persyaratan umum yang berkaitan dengan bentuk usaha dan peringkat skala usaha pada kegiatan usaha yang dijalankan dengan kriteria pemohon perizinan berusaha UMK-Perorangan. Jika persyaratan umumnya tidak sesuai dengan salah satu kriteria pemohon perizinan berusaha UMK-Perorangan, maka kegiatan usaha tersebut tidak dapat menggunakan perizinan berusaha UMK-Perorangan. *(Contoh: 1) Jika sebuah kegiatan usaha mewajibkan bentuk usahanya adalah non-perseorangan (misalnya CV atau PT), maka kamu tidak dapat menggunakan perizinan berusaha UMK-Perorangan. 2) Jika sebuah kegiatan usaha menyaratkan modal minimalnya adalah lebih dari Rp 5 miliar atau dengan kata lain tertutup bagi skala usaha mikro dan kecil, maka kamu tidak dapat menggunakan perizinan berusaha UMK-Perorangan.) Apa saja syarat untuk bisa mendapatkan perizinan berusaha UMK-Perorangan? Proses pendaftaran perizinan berusaha UMK-Perorangan yang kami lakukan adalah melalui sistem Online Single Submission (OSS) versi Risk Based Approach (RBA) yang dikelola oleh BKPM. Saat ini persyaratan pengajuan perizinan berusaha UMK-Perorangan semakin mudah dan sederhana. Yang paling utama tentu kamu harus memiliki usaha dan memenuhi persyaratan untuk bisa mendapatkan perizinan berusaha UMK-Perorangan baik dari bentuk usaha maupun permodalan. 
                            
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Dokumen legalitas apa saja yang akan saya dapatkan? </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                
                                    Kamu akan mendapatkan Nomor Induk Berusaha (NIB), Surat Pernyataan Mandiri K3L, Surat Pernyataan Mandiri Kesediaan Memenuhi Kewajiban, Surat Pernyataan Terkait Tata Ruang, SPPL, Sertifikat Standar (untuk kegiatan usaha tingkat risiko menengah rendah). 
                                
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Apakah permohonan IUMK yang saya ajukan pasti berhasil ?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                
                                    Sepanjang kamu memenuhi persyaratan, mengisi informasi yang diminta sistem OSS RBA, dan tidak ada gangguan atau kendala di sistem OSS RBA maka proses untuk mendapatkan perizinan berusaha UMK-perorangan melalui OSS RBA biasanya berhasil. 
                
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Proses apa yang dilakukan oleh Legalpedia agar saya bisa mendapatkan perizinan berusaha UMK-Perorangan? </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                
                                  Di tahap awal kamu mengisi beberapa informasi mengenai identitas diri dan usaha. Setelah melakukan pembayaran, kami akan menghubungi kamu untuk mendapatkan beberapa informasi tambahan seperti kegiatan usaha. Apabila informasinya sudah lengkap dan sesuai kami akan melanjutkan dengan proses di OSS RBA sampai selesai. 
              
                
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {service_item_api} from "@/backend-api/service_item_api"

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"

export default {
    components: {
        ContactUs, HeaderService
    },
    data: () => ({
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Izin UMKM',
                disabled: true,
            }
        ],
        umkm: null,
        details: [],
        facilities: [],
    }),
    methods:{
        async fetchData(){
            var respData = await service_item_api.front(`?service_id=1`, null, false, false, false)
            if (respData.status === 200) {
                this.umkm = respData.data.data ? respData.data.data[0] : null
                this.details = this.umkm ? this.umkm.service_item_syarats : []
                this.facilities = this.umkm ? this.umkm.service_item_facilities : []
            } 
        },
    },
    async mounted(){
        await this.fetchData()
    },
    watch: {
    }
}
</script>

<style scoped>

</style>